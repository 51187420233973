@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';

.root {
	position: fixed;
	z-index: 99999;
	bottom: 32px;
	right: 32px;
	border-radius: 8px;
	background-color: white;
	width: 100%;
	max-width: 945px;

	display: grid;
	grid-template-columns: 1fr 1fr;
	color: #000;

	.container {
		padding: 50px;

		h4 {
			font-size: 22px;
		}
	}

	.img {
		display: flex;
		align-items: stretch;
		background: #efefef;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	@include sm-down {
		grid-template-columns: 1fr;
		left: 0;
		right: 0;
		width: calc(100% - 32px);
		margin: 0 auto;
		bottom: 16px;

		.img {
			display: none;
		}
	}

	.close_button {
		position: absolute;
		top: 14px;
		right: 14px;
		z-index: 100;
		cursor: pointer;
		border: none;
		background-color: rgba(255, 255, 255, 0.75);
		border-radius: 100px;
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 30px;
		}
	}
}
