@import 'styles/constants';
@import 'styles/mixins/media-queries';

.select_wrapper {
	display: inline-block;
	min-width: 200px;
	padding: 0;
	border: 1px solid gray;
	border-radius: 5px;
	font-size: 15px;
	cursor: pointer;
	overflow: visible;
	position: relative;
	z-index: 10;

	&:global(.error) {
		:global(.placeholder) {
			color: red;
		}
		border-color: red !important;
	}

	.select_menu {
		padding-top: 5px;
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		background-color: inherit;

		ul {
			padding: 0;
			list-style-type: none;
			margin-bottom: 0;

			> li > div {
				min-height: 29px;
				padding: 5px 10px;

				&:hover,
				&:focus {
					background-color: lightgray;
				}
			}
		}
	}

	.select_button {
		position: relative;
		display: block;
		min-height: 31px;
		padding: 5px 30px 5px 10px;
		color: #000;
		font-weight: 500;
		font-size: 1.1em;

		&.label {
			font-weight: normal;
		}

		&[aria-expanded='true']::after {
			transform: translateY(-50%) rotate(180deg);
		}

		&::after {
			position: absolute;
			top: 50%;
			right: 10px;
			width: 0;
			height: 0;
			border-color: currentColor transparent transparent;
			border-style: solid;
			border-width: 7px 7px 0;
			transform: translateY(-50%);
			transition: transform 200ms ease-out;
			content: '';
		}
	}

	.clear_button {
		position: absolute;
		right: 0;
		top: 0;
		background-color: black;
		display: inline-block;
		color: currentColor;
		cursor: pointer;
		bottom: 0;
		width: 30px;
		border: none;

		&.hide_button {
			display: none;
		}
	}
}
