@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';

.blurred {
	filter: blur(1rem);
}

.content {
	background-color: #fff;
	position: relative;
	color: #000;
	overflow-y: auto;
	width: 100dvw;
	height: 100dvh;
	display: flex;
	flex-direction: row-reverse;

	@include md_up {
		p {
			text-align: left;
		}
	}

	img {
		object-fit: cover;
	}

	h3 {
		text-align: center;
	}

	&.mystery_ecap {
		h3 {
			text-align: center;
			font-size: 40px;
			font-weight: 400;
			line-height: 110%;

			@include sm-down {
				font-size: 23px;
			}
		}

		h4 {
			font-size: 20px;
			font-weight: 500;
			line-height: 110%;
			text-align: center;

			@include sm-down {
				font-size: 22px;
			}
		}

		.button_container {
			display: flex;
			grid-template-areas: 'option1';
			justify-content: center;

			.button {
				width: 100%;
			}

			@include sm-down {
				.button {
					font-size: 13px !important;
				}
			}
		}
	}
}

.content_new {
	border-radius: 15px;
	background: #fff;
	box-shadow: 6px 8px 20px 10px rgba(0, 0, 0, 0.28);

	position: relative;
	color: #000;
	overflow-y: auto;
	width: min(90vw, 680px);
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;

	&.full_screen {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		height: 100%;
		flex: 1 0 0;
		border-radius: 0;
		align-items: stretch;

		@include sm-down {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.close_button {
			position: fixed !important;
		}
	}

	.ecap_image {
		max-height: 100dvh;

		@include md-up {
			> img {
				height: 100%;
				object-position: top left;
			}
		}

		@include xs-only {
			max-height: 375px;
		}

		@include max-height(500px) {
			display: none;
		}
	}
}

.image_wrapper {
	position: relative;
	background-color: black;
	@include sm-down {
		> img {
			max-height: 360px;
		}

		// Display none on orientation horizontal with mobile
		@media (orientation: landscape) {
			display: none;
		}
	}
	@include xs-only {
		> img {
			max-height: 300px;
		}
	}
}

.overlay {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.content.full_bleed {
	width: 100vw;
	max-width: 100% !important;

	height: 100%;
	max-height: 100vh !important;

	border-radius: 0;

	display: flex;

	// padding-bottom: 4rem;

	> picture,
	> div {
		flex: 1 0 0;
		min-width: none !important;
		max-width: none !important;
	}

	img {
		object-fit: cover;
	}

	> picture > img {
		@include md-up {
			height: 100%;
			object-position: center 20%;
		}
	}

	@include sm-down {
		display: grid;
		grid-template-rows: auto 1fr;
		align-self: flex-start;
	}

	.inner_text > div {
		h3,
		p {
			line-height: 1.4;
		}

		form {
			max-width: 360px;

			button[type='submit'] {
				background-color: #000;
				border-color: #000;
				color: #fff;

				&:hover,
				&:focus {
					background-color: $brand-eight-blue;
					border-color: $brand-eight-blue;
				}
			}
		}

		h3 {
			line-height: 1.2;

			@include sm-down {
				font-size: 32px;
			}
		}
	}

	.close_button {
		position: fixed !important;
	}
}

.close_button {
	position: absolute;
	top: 1rem !important;
	right: 1.5rem !important;
	width: 24px !important;
	z-index: 100;
	color: #000;
	height: 24px !important;
	background: rgba(255, 255, 255, 0.8);
	border-radius: 999px;
	border: none;
	padding: 0;

	cursor: pointer;

	transition: all 0.25s ease-in-out;

	> img {
		width: 100%;
		height: 100%;
	}

	&:hover {
		background: white;
	}
}

.inner_text {
	padding: 24px;
	color: #000;
	@include sm-up {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	@include md-up {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 28px 24px;
		flex: 1;
	}

	max-width: 500px;
	margin: 0 auto;
}

.logo {
	display: none;

	@include md-up {
		width: 120px;
		margin: 0 auto 50px;
		display: block;
	}
}

.inner {
	min-width: 47.5% !important;
	max-width: 47.5% !important;
	@include sm_down {
		position: relative;
		max-width: 100% !important;
	}
	@include md_up {
		max-width: 300px;
	}
}

.fit_height {
	@include md-up {
		height: 100%;
		width: 100%;
		margin: auto;
	}
}

.companion_img {
	overflow: hidden;
}
.overlay_content {
	width: 100vw;
	max-width: 300px;
	color: #fff;
	text-align: center;

	h3 {
		color: var(--Temperature-text-color, #fff);
		text-align: center !important;

		font-size: 32px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 125% !important;
		letter-spacing: 0.32px !important;
	}

	p {
		color: var(--Temperature-text-color, #fff);
		text-align: center;

		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 140%;
		letter-spacing: 2px;
		text-transform: uppercase;
		margin-bottom: 0.5rem;
	}
}
.question_class {
	@include sm-down {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}
