@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';
@import 'styles/mixins/fonts-2024';
@import 'styles/colors-2024';

.complete_wrapper {
	margin: 0 auto;

	&.mystery_ecap {
		.button_container {
			display: flex;
			grid-template-areas: 'option1';
			justify-content: center;

			.button {
				width: 100%;
			}

			@include sm-down {
				.button {
					font-size: 13px !important;
				}
			}
		}
	}

	h3 {
		text-align: center;
		font-size: 44px;
		font-weight: 600;
		line-height: 110%;

		@include sm-down {
			font-size: 23px;
		}
	}

	p {
		text-align: center;
		color: #8e8d92;
		font-size: 16px;

		@include sm-down {
			font-size: 14px;
		}
	}

	div {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	@include sm-down {
		text-align: center;
	}

	.button_container {
		display: grid;
		grid-template-areas: 'option1';
		justify-content: center;

		.button {
			width: 100%;
		}

		@include sm-down {
			.button {
				font-size: 13px !important;
			}
		}
	}

	.pill_container {
		margin-bottom: 30px;

		@include sm-down {
			margin-bottom: 15px;
		}
	}

	.pill {
		border-radius: 5px;
		background: linear-gradient(91deg, rgba(1, 78, 168, 0.05) -14.01%, rgba(1, 59, 139, 0.05) 53.53%, rgba(1, 42, 109, 0.05) 115.67%);
		display: flex;
		padding: 6px 10px;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 700;
		color: $ui-holiday-blue;
		letter-spacing: 0.633px;
		text-transform: uppercase;
	}

	.explanation {
		border-radius: 4px;
		background: $background-off-white;
		display: flex;
		padding: 20px 16px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		margin-bottom: 40px;
		column-gap: 15px;

		margin-top: 1.25rem;

		.explanation_text {
			text-align: left;
		}
	}
	.headerText {
		@include sm-down {
			display: none;
		}
	}

	.supertitle {
		color: $brand-eight-blue;
		text-align: center;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 2px;
		margin-bottom: 15px;

		display: none;

		@include sm-down {
			@media (orientation: landscape) {
				display: block;
			}
		}
	}

	button {
		width: 100%;
	}
}

.quiz {
	display: flex;
	row-gap: 10px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	> button {
		padding: 20px 24px;
		border-radius: 8px;
		background: $background-off-white;
		width: 100%;
		display: flex;
		text-align: center;
		font-size: 16px;

		@include sm-down {
			font-size: 14px;
			padding: 14px 24px;
		}
	}
}

.subtitle.subtitle {
	text-align: center;
}

.button_selected {
	color: #fff;
	background: #000 !important;
}

.quiz_wrapper {
	display: flex;
	margin-top: 1.5rem;
	flex-direction: column;
	gap: 2.5rem;
}

.question {
	font-size: 20px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 140% !important;
	letter-spacing: 0.2px !important;
	margin-bottom: unset !important;
}

.new_quiz {
	// display: flex;
	// flex-direction: column;
	// gap: 1.5rem;
	h4 {
		@include headline4;
		max-width: 20ch;
	}
	.explanation_image {
		width: fit-content;
	}
	a {
		width: 100%;
	}
	.explanation {
		align-items: flex-start;
	}
}
