@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';
@import 'styles/colors-2024';

// Entire modal container (including background)
.modal_content_container {
	background-color: #fff;
	border-radius: 5px;
	position: relative;
	color: #000;
	display: flex;
	flex-direction: column;
	min-height: 400px;
	justify-content: center;
	overflow-y: auto;

	margin: 15px;
	line-height: 1.4;

	@include md-up {
		display: flex;
		overflow-x: hidden;
		overflow-y: auto;
		max-width: 799px;
		width: 100%;
		height: auto;
		padding: 0;
		justify-content: space-around;
		margin: 0 20px;
	}
}
.thanksMessage {
	margin-bottom: 10px;
}

.button_container {
	margin: 10px 0;
	width: 100%;
}

.close_button {
	position: absolute;
	top: 14px;
	right: 14px;
	z-index: 100;
	cursor: pointer;
	border: none;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 12px;
	}
}

.cap_container {
	margin: 40px 0;
	padding: 4rem 50px;
}

@include md-up {
	.cap_container {
		padding-left: 0px;
	}
}

.info_container {
	color: black;

	h2 {
		max-width: 16ch;
	}
}

.modal_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;

		&:first-child {
			@include md-up {
				height: 100%;
			}
		}
	}

	@include sm-down {
		height: 100%;

		> div {
			height: 100%;
		}
	}
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;
	width: 100%;

	input,
	button {
		width: 100%;
	}

	input {
		padding: 15px;
		font-size: 16px;
		border: 1px solid gray !important;
		margin: 10px 0 24px;
	}

	p {
		color: $error;
		font-size: 14px;
		margin: 0px;
	}

	p.sms_disclaimer {
		color: #000;
		font-size: 10px;
		line-height: 12px;
		opacity: 0.4;
	}
}

div .completion_message {
	padding: 10px;
	margin: 10px;
}

.promoImg {
	width: 100%;
	margin: 0;
	border-radius: 0 !important;
}

.promoSubtitle {
	font-weight: bold !important;
	font-size: 16px;
	margin: 24px 0;
	line-height: 1.4;
}

p.disclaimer {
	text-align: center;
	font-size: 11px;
	line-height: 1.4;
	color: #8e9199;
	margin-top: 25px;
}

.button.button {
	margin: auto;
	text-align: center;
	width: 100%;
	font-size: 14px;
	text-decoration-line: underline;

	margin-top: 10px;

	color: #8d9098;
}

.dont_want_button.dont_want_button {
	text-align: center;
	width: 100%;
	font-size: 14px;
	text-decoration-line: underline;

	color: #8d9098;

	margin: 12px auto 18px;
}

.exit_intent_image.exit_intent_image {
	@include sm-down {
		display: none !important;
	}
}

div.root {
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 60px;

	@include sm-down {
		grid-template-columns: 1fr;

		.img {
			display: none;
		}
	}

	@include md-up {
		max-height: 550px;
	}

	.img {
		img {
			height: 100%;
		}
		height: 100%;
		max-height: 550px;
		object-fit: contain;
		object-position: center;
		background-color: #efefef;
	}

	.cap_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		margin: 15px auto;

		@include sm-down {
			padding: 0 40px;
			max-width: 400px;
		}
	}

	.suptitle {
		color: $brand-eight-blue;
	}

	.logo {
		margin-bottom: 30px;
		width: 95px;

		@include sm-down {
			display: none;
		}
	}

	.email_input input {
		border: none !important;
		border-bottom: 1px solid #636363 !important;
		font-size: 14px;
		font-family: var(--neue-montreal), sans-serif;
	}
}

.promoDescription.promoDescription {
	margin-top: 1.25rem;
}
