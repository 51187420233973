@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';
@import 'styles/colors-2024';

// Entire modal container (including background)
.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow-y: auto;
	margin: 0 20px;
	line-height: 1.4;

	@include minmax(450px, 599px) {
		overflow: auto;
	}

	@include sm-up {
		display: flex;
		overflow-x: hidden;
		overflow-y: auto;
		width: 360px;
		height: auto;
		padding: 0;
	}

	p {
		color: #000;
		margin-top: 0;
		font-weight: 400;
		font-size: 16px;
		max-width: 308px;
		line-height: 1.4;
		margin-bottom: 24px;
	}
}

.close_button {
	position: absolute;
	top: 14px;
	right: 14px;
	z-index: 100;
	cursor: pointer;
	border: none;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 12px;
	}
}

.modal_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 25px 15px 45px;

	> h3 {
		color: #000;
		margin-bottom: 24px;
		font-weight: 500;
		font-size: 24px;
		max-width: 240px;
		line-height: 1.3;
	}
}

.form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	gap: 18px;

	input,
	button {
		width: 100%;
	}

	input {
		padding: 15px;
		font-size: 16px;
		border: 1px solid #d9d9d9 !important;
		border-radius: 5px;
		margin: 0;
	}

	button {
		margin-top: 15px;
	}

	p {
		color: $error;
		font-size: 14px;
		margin: 0;
	}
}

.promoImg {
	width: calc(100% + 30px);

	// margin: -25px -15px 25px;
	margin-top: -25px;
	background-color: black;
}

.valentinesbutton {
	background-color: #85353f;
	border-color: #85353f;
	&:hover {
		background-color: #85353f;
		border-color: #85353f;
		opacity: 0.9;
	}
}

.title {
	margin-top: 2rem;
}

.gold {
	margin-top: 50px;
	margin-bottom: 10px;
	color: $text-grey-dark;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-align: center;
}
