@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/theme';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';

.shortcut {
	position: fixed;
	bottom: 10px;
	left: 10px;
	z-index: 2;

	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transform: translateY(100%);

	&.visible {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transform: translateY(0);
	}

	display: flex;
	align-items: center;

	button {
		color: $text-white;

		&:first-child {
			padding: $spacing-x-small-desktop 0 !important;
			padding-left: $spacing-medium-desktop !important;
			padding-right: $spacing-xxx-small-desktop !important;
		}

		&:last-child {
			padding: $spacing-x-small-desktop 0 !important;
			padding-right: $spacing-medium-desktop !important;
			padding-left: $spacing-xxx-small-desktop !important;
		}
	}

	color: $text-white;
	background: linear-gradient(81.29deg, #000000 0.36%, #05141b 52.54%, #051726 85.03%, #000000 102.35%);
	border-radius: $spacing-xxx-small-desktop;

	svg {
		width: 1.5rem;
		height: 1.5rem;
		transform: translateY(-5%);
	}

	&:hover {
		filter: brightness(0.9);
	}

	transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
}

.pdp_shortcut {
	@include sm-down {
		display: none !important;
	}
}
