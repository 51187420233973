@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';

.waypoint {
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	height: 5px;
	pointer-events: none;
	opacity: 0;
}

.modal.modal {
	position: fixed;
	bottom: 30px;
	right: 30px;
	left: auto;
	top: auto;
	background: transparent;
	width: auto;
	height: auto;
	box-shadow: 2px 4px 6px rgb(0 0 0 / 30%);
	border-radius: 3px;
	transform: translateY(0%);
	transition: transform 300ms ease-in-out;

	@include xs-only {
		bottom: 15px;
		left: 15px;
		right: 15px;
		max-width: calc(100vw - 30px);
	}
}

.modal.outside {
	transform: translateY(calc(100% + 30px));
}

.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow-y: auto;
	line-height: 1.4;
	padding: 60px 24px 20px;
	color: #000;
	width: 100%;

	@include sm-up {
		display: flex;
		overflow-x: hidden;
		overflow-y: auto;
		width: 360px;
		height: auto;
	}
}

.modal_content.modal_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	min-height: 215px;

	@include sm-up {
		min-height: 297px;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

		p {
			margin-bottom: 15px;

			&.subtitle {
				margin-bottom: 0;
				line-height: 1.4;

				@include xs-only {
					max-width: 180px;
				}
			}
		}
	}

	input {
		border: 1px solid rgb(0 0 0 / 10%);
		border-radius: 3px;
	}

	.mobile_inline_form {
		@include xs-only {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			> p {
				order: 0;
				flex: 1 0 100%;
				width: 100%;
				margin-bottom: 0;

				&.disclaimer {
					order: 2;
				}
			}

			> input {
				flex: 2 0 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				order: 1;
			}

			> button {
				min-width: 115px;
				flex: 0 1 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				order: 2;
			}
		}
	}
}

.close_button {
	position: absolute;
	top: 14px;
	right: 10px;
	z-index: 100;
	cursor: pointer;
	border: none;
	background-color: rgb(255 255 255 / 75%);
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 36px;

	img {
		width: 18px;
	}
}

.thank_you {
	font-weight: 700;
	font-size: 24px;
	text-align: center;
	margin-top: 25px;
}

.ecap_heading {
	font-size: 20px;
	margin: 0;
}

.discount_heading {
	font-size: 44px;
	line-height: 140%;
	margin: 8px;
}

.subheading {
	font-weight: 400;
	font-size: 14px;
	line-height: 140%;
	max-width: 250px;
	margin-bottom: 0 !important;
}

.dont_want {
	margin: auto;
	text-align: center;
	width: 100%;
	font-size: 14px;
	text-decoration-line: underline;

	margin-top: 10px;

	color: #8d9098;
}
