@import 'styles/constants';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts_legacy';
@import 'styles/colors-2024';
@import 'styles/layout-tokens';

.ecap_content_wrapper {
	text-align: center;
	margin: auto;
	width: 100%;
	padding: 4rem;

	@include sm-down {
		padding: 2.5rem $spacing-medium-mobile;
		align-self: flex-start;
	}

	.quiz {
		display: flex;
		row-gap: 10px;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 40px;

		> button {
			padding: 15px 24px;
			border-radius: 8px;
			background: $background-accent-blue-10;
			width: 100%;
			display: flex;
			text-align: center;
			font-size: 18px;

			@include sm-down {
				font-size: 14px;
				padding: 14px 24px;
			}
		}

		@include sm-down {
			margin-top: 30px;
			margin-bottom: 25px;
		}

		@include max-height(500px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 10px;
		}
	}

	.quiz_question {
		color: $text-grey-dark;
		max-width: 35ch;
		margin: 1.5rem auto 0;
	}

	.eyebrow {
		color: $text-accent-blue-light-mode;
		margin-bottom: 10px;
	}

	.dismiss {
		color: $text-grey-dark;
		font-weight: 500;
		text-decoration: underline;
		font-size: 1rem;
	}
}

.checkmark {
	width: 2rem;
	height: 2rem;
	margin-inline: auto;
	margin-bottom: 0.5rem;
}

.disclaimer {
	color: $text-grey-dark;
	max-width: 430px;
	margin: 40px auto 0;

	@include xs-only {
		margin: 20px auto 0;
	}
}

.children_wrapper {
	max-width: 430px;
	margin: 40px auto 0;

	@include xs-only {
		margin: 20px auto 0;
	}
}

.email_form {
	margin: 10px auto 0;
	width: 100%;

	input {
		width: 100%;
		font-size: 16px;
		padding: 10px;
		min-height: 30px;
		border: none;
		outline: none;
		border-radius: 0;
		border-bottom: 1px solid #636363;
		box-shadow: none;

		&:focus {
			outline: none;
			border-color: #000;
			box-shadow: 0 1px 0 0 #000;
		}
	}

	button {
		width: 100%;
		margin: 30px 0;
	}
}

p.error {
	color: $error;
	font-size: 16px;
	margin: 10px 0;
	font-weight: 500;
	text-align: center;
}

.arrow.arrow {
	width: 1.2em;
	height: 1.2em;
	margin-left: 2px;
}

button.submitbutton {
	font-size: 1.1rem;
	width: 100%;
	padding: 14px 30px;

	@include sm-down {
		font-size: 1rem;
	}
}

.headline.headline {
	max-width: 22ch;
	margin-inline: auto;
}

.highlight_small {
	border-radius: 8px;
	opacity: 0.8;
	background: var(--brand-royal-10, rgba(24, 98, 255, 0.1));
	margin: 3rem auto 0;
	width: 100%;
	text-align: center;
	padding: 24px 16px;
}

.highlight {
	border-radius: 8px;
	opacity: 0.8;
	background: var(--brand-royal-10, rgba(24, 98, 255, 0.1));

	display: flex;
	width: 100%;
	max-width: 436px;
	padding: 64px 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;

	margin: 4rem auto 0;

	.author {
		color: rgba(0, 0, 0, 0.7);
		font-size: 17px;
		font-weight: 500;
	}

	.stars {
		svg {
			width: 1rem;
			height: 1rem;
		}
	}

	.star_reviews > a {
		color: #000;
		text-decoration: underline;
	}
}
