@import 'styles/constants.scss';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/utilities';

.modal_content_container {
	background-color: #fff;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	overflow: visible;
	margin: 0 20px;
	line-height: 1.4;

	@include sm-up {
		display: flex;
		width: 360px;
		height: auto;
		padding: 0;
	}
}

.select_wrapper {
	background-color: white;
	width: 100%;
	text-align: left;

	&.first_select {
		z-index: 11;
		margin-bottom: 20px;
	}

	:global(.select_menu) {
		margin-top: 2px;
		border: 1px solid gray;
		border-top: none;
	}

	.menu_item {
		color: black;
		display: flex;
		align-items: center;
		column-gap: 10px;

		> svg {
			width: 20px;
			height: 20px;
		}
	}

	.display_item {
		display: flex;
		height: 100%;
		flex-direction: column;

		small {
			font-weight: 400;
			font-size: 12px;
			color: #797c8e;
		}
	}

	span {
		font-weight: 400;
		color: black;
	}
}

.save_button {
	margin-top: 20px;
	width: 100%;
}

.close_button {
	position: absolute;
	top: 14px;
	right: 14px;
	z-index: 100;
	cursor: pointer;
	border: none;
	background-color: rgba(255, 255, 255, 0.75);
	border-radius: 100px;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 12px;
	}
}

.modal_content {
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 40px 35px 35px;

	> p {
		color: #000;
		margin-bottom: 25px;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.4;
		max-width: none;

		&:first-of-type {
			font-weight: 700;
			font-size: 16px;
		}
	}
}
